<template>
  <form
    :is="tag"
    v-test="'form'"
    novalidate
    class="needs-validation"
    @submit.prevent="submit">
    <FormField
      v-for="field in currentForm"
      :key="'form--' + name + '__' + field.name"
      row
      :name="name"
      :sub="sub"
      :field-data="field"
      :v="v"
      @submit="submit" />
    <input
      type="submit"
      hidden>
  </form>
</template>

<script>
import FormField from '@/components/forms/FormField';

export default {
  name: 'ValidationForm',
  components: { FormField },
  props: {
    /**
     * Vuelidate model.
     */
    v: {
      type: Object,
      required: true,
    },

    /**
     * Form name.
     */
    name: {
      type: String,
      default: () => 'form',
    },

    /**
     * The form to render.
     */
    form: {
      type: Array,
      default: null,
      required: true,
    },

    /**
     * Key of sub form, if any.
     */
    sub: {
      type: String,
      default: null,
    },

    /**
     * The tag to use for the outer element.
     */
    tag: {
      type: String,
      default: 'form',
    },
  },

  computed: {
    /**
     * Uses the sub prop to find the current sub form, if any.
     *
     * @returns {Object[]}
     */
    currentForm() {
      if (this.sub) {
        return this.form.find(({ name }) => name === this.sub).children;
      }

      return this.form;
    },
  },

  methods: {
    /**
     * Submits the form.
     */
    submit() {
      this.v.$touch();

      if (this.v.$pending || this.v.$anyError) {
        return;
      }

      this.$emit('submit');
    },
  },
};
</script>
