<template>
  <Card
    tooltip="social_shipping.contact_description"
    title="social_shipping.contact_title">
    <ValidationForm
      tag="div"
      class="mt-4"
      :form="socialShippingFormBus.formFields"
      sub="contactForm"
      :v="socialShippingFormBus.$v"
      @submit="onSubmit" />
  </Card>
</template>

<script>
import Card from '@/components/Card';
import ValidationForm from '@/components/forms/ValidationForm';
import { socialShippingFormBus } from '@/views/SocialShipping/socialShippingFormBus';

export default {
  name: 'SocialShippingContactForm',
  components: {
    Card,
    ValidationForm,
  },
  data() {
    return {
      socialShippingFormBus,
    };
  },
  methods: {
    onSubmit() {
      socialShippingFormBus.$emit('submit');
    },
  },
};
</script>
